import { useHistory, useLocation } from 'react-router-dom';

function useSearchParamsWithRouter() {
  const location = useLocation();
  const history = useHistory();

  const urlSearchParams = new URLSearchParams(location.search);

  function addUrlSearchParam(key, value) {
    urlSearchParams.set(key, value);
    updateRouterLink();
  }

  function deleteUrlSearchParam(key) {
    urlSearchParams.delete(key);
    updateRouterLink();
  }

  function getUrlSearchParam(key) {
    return urlSearchParams.get(key);
  }

  function resetUrlSearchParam() {
    updateRouterLink(true);
  }

  function updateRouterLink(reset = false) {
    history.push({
      pathname: location.pathname,
      search: reset ? '' : `?${urlSearchParams.toString()}`,
    });
  }

  function addMultipleUrlSearchParams(searchParams = []) {
    searchParams.forEach(({ key, value }) => urlSearchParams.set(key, value));
    updateRouterLink();
  }

  return {
    addUrlSearchParam,
    deleteUrlSearchParam,
    getUrlSearchParam,
    resetUrlSearchParam,
    addMultipleUrlSearchParams,
  };
}

export default useSearchParamsWithRouter;
